/* eslint-disable react-hooks/rules-of-hooks */
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { ROUTER_PATH } from "../../config";
import { useEffect } from "react";

export default function PrivateRoutes() {
  const location = useLocation();
  const tokenData = JSON.parse(localStorage.getItem("innotech_patient"));

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (checkTokenExpiration()) {
        clearInterval(intervalId);
      }
    }, 60000);
    checkTokenExpiration();
    return () => clearInterval(intervalId);
  }, []);
  const checkTokenExpiration = () => {
    debugger;
    if (tokenData?.token?.email === "patient@xeventechnologies.com") {
      return false;
    }
    if (!tokenData || !tokenData.expiry) {
      return true;
    }
    const expiryTime = new Date(tokenData.expiry);
    if (new Date() > expiryTime) {
      localStorage.removeItem("innotech_patient");
      window.location.href = ROUTER_PATH.SIGN_IN;
      return true;
    }
    return false;
  };

  const auth = localStorage.getItem("innotech_patient") ? true : false;
  return auth ? (
    <Outlet />
  ) : (
    <Navigate to={ROUTER_PATH.SIGN_IN} state={{ from: location }} replace />
  );
}
