// import InnotechLogo from "./innotech-logo.svg";
import InnotechLogo from "./innotech-logo.webp";
import InnotechLogin from "./innotech-login.png";
// import InnotechLogo from "./innotech-logo.svg";
import SidebarLogo from "./SideBarLogo.svg";

import DateIcon from "./date_icon.svg";
import TimeIcon from "./time_icon.svg";
import FolloUpIcon from "./follow_up_icon.svg";
import DoctorAvatar from "./doctor-avatar.svg";
import UserAvatar from "./user-avatar.svg";
import ThankYouIcon from "./thank-you-icon.svg";
import ChatAvatar from "./chat-avatar.svg";
import Step1 from "./step_1.svg";
import Step2 from "./step_2.svg";
import Step3 from "./step_3.svg";
import Step4 from "./step_4.svg";
import Step5 from "./step_5.svg";
import Step6 from "./step_6.svg";
import MaleCharacter from "./male-standing.png";
import FemaleCharacter from "./female-standing.png";
// import FemaleDoctor from "./female_correct.mp4";
import FemaleDoctor from "./Female-Video.mp4";
import MaleDoctor from "./Male-Video.mp4";
import femaleAvatar from "./female-avatar.jpg";

// import MaleDoctor from "./MaleDoctorTalking.mp4";

const IMAGES = {
  INNOTEXH_LOGIN: InnotechLogin,
  FEMALE_AVATAR: femaleAvatar,
  SIDEBAR_LOGO: SidebarLogo,
  INNOTECH_LOGO: InnotechLogo,
  DATE_ICON: DateIcon,
  TIME_ICON: TimeIcon,
  FOLLOW_UP_ICON: FolloUpIcon,
  DOCTOR_AVATAR: DoctorAvatar,
  USER_AVATAR: UserAvatar,
  THANKYOU_ICON: ThankYouIcon,
  CHAT_AVATAR: ChatAvatar,
  STEP_ICON_1: Step1,
  STEP_ICON_2: Step2,
  STEP_ICON_3: Step3,
  STEP_ICON_4: Step4,
  STEP_ICON_5: Step5,
  STEP_ICON_6: Step6,
  MALE_CHARACTER: MaleCharacter,
  FEMALE_CHARACTER: FemaleCharacter,
  FEMALE_DOCTOR: FemaleDoctor,
  MALE_DOCTOR: MaleDoctor,
};

export default IMAGES;
